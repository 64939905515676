
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  watch,
} from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import { searchPaymentsForRefund } from "@/api/payment.api";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface StatusCode {
  code: string;
  description: string;
}


export interface Data {
  items: { unappliedCredit: number }[];
  payment: any;
  patientId: any;
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  dict: {
    paymentTypes: [];
  };
  postingErrors: string[];
}
export default defineComponent({
  name: "ServiceHistoryPayment",
  props: ["patientId", "payment"],
  components: { DecimalInput, PaginationUi },
  setup(props, ctx) {
    const store = useStore();
    const router = useRouter();
    const orderList = [
      {
        name: "Entry Date",
        key: "entryDate",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Transaction ID",
        key: "paymentId",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Payer",
        key: "Payee",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Payment Type",
        key: "paymentType",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Ref #",
        isFilter: true,
        key: "reference",
        keyword: null,
      },
      {
        name: "Unapplied Credit Amount",
        key: "unappliedCredit",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Refund Amount",
        key: "refundAmount",
        isFilter: false,
        keyword: null,
      },
    ];

    let organizationId = ref<string | null>("");

    watch(
      () => props.patientId,
      async (currentValue, oldValue) => {
        if (currentValue) {
          data.pagination.pageSize = 50;
          await getAll();
        } else {
          data.items = [];
          ctx.emit("servicesReceived", data.items);
          ctx.emit("totalBalanceDueUpdated", 0);
          data.pagination.currentPage = 1;
          data.pagination.totalPages = 0;
          data.pagination.totalCount = 0;
          data.pagination.pageSize = 50;
        }
      },
      { deep: true }
    );

    let data = reactive<Data>({
      payment: undefined,
      patientId: props.patientId,
      items: [],
      keyword: "",
      orderBy: "defaultPaymentOrder",
      IsAdvancedOrderBy: true,
      isDecr: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      dict: {
        paymentTypes: [],
      },
      postingErrors: [],
    });

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "defaultPaymentOrder";
      data.IsAdvancedOrderBy = true;
      orderList.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.payment = props.payment;
      data.dict.paymentTypes = store.getters.allPaymentTypes;

      await getAll();
      data.pagination.pageSize = data.pagination.totalCount;
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function search() {
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      if (!props.patientId) {
        return;
      }
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let constList = orderList
        .filter((item) => {
          return !item.IsAdvancedOrderBy;
        })
        .map((item) => {
          return item.key;
        });
      constList.push("patient.firstName");
      let request = {
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        PatientId: props.patientId,
        TransactionTypes: [0],
        WithPositiveUnappliedCredit: true,
      };

      const res = await searchPaymentsForRefund(request);
      data.items = res.data;
      ctx.emit("servicesReceived", data.items);
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      ctx.emit("totalBalanceDueUpdated", res.totalBalanceDue);
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function amountChanged() {
      ctx.emit("amountChanged");
    }

    return {
      organizationId,
      clearSearch,
      data,
      orderList,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      amountChanged,
      pageChanged,
      pageSizeChanged,
    };
  },
});
